import React from 'react';
import { Container, IconSad } from './styles';

export const ExpiredToken = () => {
  return (
    <Container>
      <div className="AlertDiv">
        <IconSad />
        <h1 className="TitleAlert">Não foi possível concluir esta ação!</h1>
        <p>O link da ação requisitada está vencido.</p>
      </div>
    </Container>
  );
};
