import styled from 'styled-components';

export const Container = styled.div`
  background-color: #151515;
  color: white;
  padding: 10px;
  text-transform: uppercase;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.03);
  width: 300px;
  box-sizing: border-box;
`;

export const Button = styled.div`
  margin-left: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #ffffff;
`;
