import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;

  h1,
  h2 {
    font-size: 25px;
    margin: 0 0 5px;
  }

  p {
    font-size: 20px;
    margin: 0 0 30px;
  }

  svg {
    scale: 2;
    margin-bottom: 20px;
  }
`;

export const ModalAlert = styled.div`
  background-color: #fff;
  border-radius: 10px;
  width: 400px;
  padding: 30px;
  box-shadow: 0px 0px 15px 0px #777777cc;
  display: flex;
  flex-direction: column;
`;

export const IconSad = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title />
    <path d="M64,0a64,64,0,1,0,64,64A64.07,64.07,0,0,0,64,0Zm0,122a58,58,0,1,1,58-58A58.07,58.07,0,0,1,64,122Z" />
    <path d="M90,61H38a3,3,0,0,0,0,6H90a3,3,0,0,0,0-6Z" />
  </svg>
);
