import React from 'react';
import { Logo } from '../Logo';
import { Container, GradientLine } from './styled';

export const Header = () => {
  return (
    <Container>
      <Logo />
      <GradientLine />
    </Container>
  );
};
