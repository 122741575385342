import React from 'react';
import { Container } from './styles';

interface WarningProps {
  children: React.ReactNode;
  color?: string;
}

export const Warning = (props: WarningProps) => {
  const { color } = props;

  return (
    <Container color={color}>
      <button>X</button>
      <p className="description">{props.children}</p>
    </Container>
  );
};
