import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;

  h1,
  h2 {
    font-size: 25px;
    margin: 0 0 5px;
  }

  p {
    font-size: 20px;
    margin: 0 0 30px;
  }

  svg {
    scale: 2;
    margin-bottom: 20px;
  }

  div.MobilePhones a {
    padding: 10px;
  }
`;
