import { useEffect, useState } from 'react';
import {
  transitions,
  positions,
  Provider as AlertProvider,
  useAlert,
} from 'react-alert';

import useYup from '@usereact/use-yup';
import {
  DivBody,
  Input,
  Button,
  Main,
  Titles,
  Alert,
  DivInput,
  DivEyeIcon,
} from './styles';

import { BsEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import { Loader, PasswordStrength, AlertTemplate } from '../../components';
import { changePassword } from './validations/formSchema';
import { useNavigate } from 'react-router-dom';
import { ITokenData } from './interfaces/ITokenData';
import { Helmet } from 'react-helmet-async';
import { WithAutorization } from '../../Container/Authorization/withAutorization';
import api from '../../../core/api';

const isNumberRegx = /\d/;
const specialCharacterRegx = /[;:"'?\-\_.!@#$%^&*(){}\[\]<>/|\\]/;
const lowerUpcase = /^(?=.*[A-Z])(?=.*[a-z]).*$/;
interface changePasswordParams {
  newPassword?: string;
}

const URL_SUCCESS = '/successfully/changed-password';

function ChangePasswordPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [touched, setTouched] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [password, setPassword] = useState({
    newPassword: '',
    confirmNewPassword: '',
  });
  const [passwordValidity, setPasswordValidity] = useState({
    minChar: null,
    number: null,
    specialChar: null,
    lowerUpcase: null,
  });

  let { errors } = useYup(password, changePassword, {
    validateOnChange: true,
  });

  const alert = useAlert();

  const handleChange = ({ target }: any) => {
    const { name, value } = target;

    setPassword({
      ...password,
      [name]: value,
    });

    setTouched({
      //@ts-ignore
      ...touched,
      [name]: true,
    });

    setPasswordValidity({
      minChar: password.newPassword.length >= 8 ? true : false,
      number: isNumberRegx.test(password.newPassword) ? true : false,
      lowerUpcase: lowerUpcase.test(password.newPassword) ? true : false,
      specialChar: specialCharacterRegx.test(password.newPassword)
        ? true
        : false,
    });
  };

  function handleChangeTypeInput() {
    if (showPassword) {
      return setShowPassword(false);
    }
    setShowPassword(true);
  }

  function handleChangeTypeInput2() {
    if (showPassword2) {
      return setShowPassword2(false);
    }
    setShowPassword2(true);
  }

  function isEmpty(obj: {
    newPassword?: string | undefined;
    confirmNewPassword?: string | undefined;
  }) {
    for (let property in obj) {
      if (obj.hasOwnProperty(property)) return false;
    }

    return true;
  }

  async function changePasswordService(params: changePasswordParams) {
    try {
      const { newPassword } = params;
      return await api.init.post('/patient/change-password', {
        password: newPassword,
      });
    } catch (error) {
      throw new Error('Não foi possivel realizar a ativação da senha');
    }
  }

  async function handleSubmit(e: { preventDefault: () => void }) {
    e.preventDefault();

    if (!isEmpty(errors)) {
      console.log('erro');
      console.log(errors);
      return;
    }

    setLoading(true);
    try {
      const response: any = await changePasswordService({
        newPassword: password.newPassword,
      });

      if (!response) alert.show('Ocorreu um erro ao alterar sua senha');

      setPassword({
        newPassword: '',
        confirmNewPassword: '',
      });

      setDisable(true);
      navigate(URL_SUCCESS);
    } catch (error) {
      return alert.show('Ocorreu um erro ao alterar sua senha');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setPasswordValidity({
      minChar: password.newPassword.length >= 8 ? true : false,
      number: isNumberRegx.test(password.newPassword) ? true : false,
      lowerUpcase: lowerUpcase.test(password.newPassword) ? true : false,
      specialChar: specialCharacterRegx.test(password.newPassword)
        ? true
        : false,
    });
  }, [password]);

  return (
    <DivBody>
      <Helmet>
        <title>Alteração de Senha - Albert Einstein Israelite Hospital</title>
        <meta name="description" content="Alteração de Senha" />
      </Helmet>
      {loading && <Loader />}
      <Main onSubmit={handleSubmit}>
        <Titles>
          <h3>Alterar senha</h3>
          <p>Informe a nova senha para recuperação de sua conta</p>
        </Titles>
        <DivInput>
          <Input
            disabled={disable}
            name="newPassword"
            value={password.newPassword}
            type={showPassword ? 'text' : 'password'}
            placeholder="Senha"
            onChange={handleChange}
            onPaste={e => {
              e.preventDefault();
              return false;
            }}
            onCopy={e => {
              e.preventDefault();
              return false;
            }}
          />
          <DivEyeIcon>
            {!showPassword && (
              <BsFillEyeSlashFill onClick={handleChangeTypeInput} />
            )}
            {showPassword && <BsEyeFill onClick={handleChangeTypeInput} />}
          </DivEyeIcon>
        </DivInput>

        <Alert>
          {(touched as any).newPassword && errors && errors.newPassword}
        </Alert>
        <DivInput>
          <Input
            disabled={disable}
            name="confirmNewPassword"
            value={password.confirmNewPassword}
            type={showPassword2 ? 'text' : 'password'}
            placeholder="Confirme sua senha"
            onChange={handleChange}
            onPaste={e => {
              e.preventDefault();
              return false;
            }}
            onCopy={e => {
              e.preventDefault();
              return false;
            }}
          />
          <DivEyeIcon>
            {!showPassword2 && (
              <BsFillEyeSlashFill onClick={handleChangeTypeInput2} />
            )}
            {showPassword2 && <BsEyeFill onClick={handleChangeTypeInput2} />}
          </DivEyeIcon>
        </DivInput>
        <Alert>
          {(touched as any).confirmNewPassword &&
            errors &&
            errors.confirmNewPassword}
        </Alert>

        <PasswordStrength validity={passwordValidity} />

        <Button type="submit">Alterar</Button>
      </Main>
    </DivBody>
  );
}

const options = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
};

function ChangePasswordWrapper(props: ITokenData) {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <ChangePasswordPage />
    </AlertProvider>
  );
}

export const ChangePassword = () => WithAutorization(ChangePasswordWrapper);
