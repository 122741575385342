import styled from 'styled-components';

import { FaCheck, FaCircle, FaTimes } from 'react-icons/fa';

export const DivBody = styled.div`
  background-color: #999999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Main = styled.form`
  background-color: #fff;
  border-radius: 10px;
  width: 400px;
  padding: 30px;
  box-shadow: 0px 0px 15px 0px #777777cc;
  display: flex;
  flex-direction: column;

  @media (max-width: 500px) {
    box-shadow: none;
    width: 90%;
  }
`;

export const DivInput = styled.div`
  width: 100%;
  position: relative;
`;

export const DivEyeIcon = styled.div`
  top: 20px;
  right: 15px;
  position: absolute;
  cursor: pointer;
`;

export const Input = styled.input`
  width: 95%;
  padding: 10px 7px;
  text-align: left;
  font-size: 16;
  border: 0;
  border-bottom: 1px solid #ddd;
  border-left: 5px solid transparent;
  margin: 10px 0;

  &:focus {
    outline: none;
    border-left: 5px solid #2196f3;
    transition: 0.2s;
  }
`;

export const Titles = styled.div`
  h3 {
    font-size: 18px;
    margin: 0 0 5px;
  }

  p {
    font-size: 14px;
    margin: 0 0 30px;
  }
`;

export const Button = styled.button`
  width: 100%;
  border: 0;
  border-radius: 25px;
  padding: 15px 15px;
  margin: 10px 0;
  text-align: center;
  color: white;
  background-color: #2196f3;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
`;

export const Span = styled.span`
  font-size: 12px;
  color: gray;
`;

export const Alert = styled.span`
  margin-bottom: 10px;
  font-size: 14px;
  color: red;
`;

export const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 10px;
`;

export const CircleIcon = styled(FaCircle)`
  font-size: 6px;
`;

export const CheckIcon = styled(FaCheck)`
  font-size: 6px;
`;

export const InvalidIcon = styled(FaTimes)`
  font-size: 6px;
`;
