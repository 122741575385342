import styled from 'styled-components';

export const Container = styled.div`
  svg#logo,
  img#logo {
    height: 40px;
    margin: 10px 20px;
  }
`;

export const GradientLine = styled.div`
  height: 8px;
  background-image: linear-gradient(
    to right,
    #ecb561 0%,
    #de656b 30%,
    #4595c4 60%,
    #3ad5ca 100%
  );
`;
