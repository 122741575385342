import React from 'react';
import { IconSad } from '../ExpiredToken/styles';
import { Container, IconDenied } from './styles';

export function FailedActivated() {
  return (
    <Container>
      <IconDenied />
      <h1>Não foi possível ativar a sua conta!</h1>
      <p>Tente novamente, seguindo as instruções abaixo:</p>
      <ul>
        <li>
          Acesse a plataforma{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://telemedicina.einstein.br/"
          >
            Telemedicina
          </a>
        </li>
        <li>
          Realize o login clicando em <strong>Entrar</strong>
        </li>
        <li>
          Clique no botão <strong>Esqueci minha senha</strong> para receber um
          novo link de ativação de conta
        </li>
      </ul>
    </Container>
  );
}
