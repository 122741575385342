import axios from 'axios';
// TODO: define env constant
const REACT_APP_URL_BASE_COGNITO_FRONT = process.env.REACT_APP_API;
export default class api {
  static authToken = '';
  public static init = (() => {
    const apiInstance = axios.create({
      baseURL: REACT_APP_URL_BASE_COGNITO_FRONT,
    });

    apiInstance.interceptors.request.use((config: any) => {
      try {
        let token =
          this.authToken || window.localStorage?.getItem('authToken') || '';
        console.log(`api.init.tokenAuth: ${token}`);

        if (token) {
          config.headers.authtoken = `${token}`;
        }
      } catch (err) {
        console.error('api:token:error:' + err);
      } finally {
        return config;
      }
    });
    return apiInstance;
  })();
  static setAuthToken = (value: string) => {
    api.authToken = value;
  };
}
