import * as yup from 'yup';

export const changePassword = yup.object().shape({
  newPassword: yup
    .string()
    .trim()
    .required('')
    .min(8, '')
    //@ts-ignore
    .test('password-text', '', (value = '') => {
      return (value || '').match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[;:"'?\-\_.!@#$%^&*(){}\[\]<>/|\\])[0-9a-zA-Z$;:"'?\-\_.!@#$%^&*(){}\[\]<>/|\\]{8,}$/,
      );
    }),
  confirmNewPassword: yup
    .string()
    .trim()
    .required('')
    .min(8, '')
    .test('confirm-password', 'Senhas divergentes', function (value) {
      if (this.parent && this.parent.newPassword !== value) {
        return false;
      } else {
        return true;
      }
    }),
});
