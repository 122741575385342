import React from 'react';
import InfoIcon from './icons/InfoIcon';
import SuccessIcon from './icons/SuccessIcon';
import ErrorIcon from './icons/ErrorIcon';
import CloseIcon from './icons/CloseIcon';
import { Button, Container } from './styles';

const AlertTemplate = ({ message, options, style, close }: any) => {
  return (
    <Container style={{ ...style }}>
      {options.type === 'info' && <InfoIcon />}
      {options.type === 'success' && <SuccessIcon />}
      {options.type === 'error' && <ErrorIcon />}
      <span style={{ flex: 2 }}>{message}</span>
      <Button onClick={close}>
        <CloseIcon />
      </Button>
    </Container>
  );
};

export { AlertTemplate };
