import { App } from './app/index';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { configureAppStore } from './store/configureStore';

const store = configureAppStore();

export default function Root() {
  return (
    <>
      <Provider store={store}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Provider>
    </>
  );
}
