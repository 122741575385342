import React from 'react';

import {
  Ul,
  Span,
  CircleIcon,
  CheckIcon,
  InvalidIcon,
} from '../../pages/ChangePassword/styles';

export const PasswordStrength = ({
  validity: { minChar, number, specialChar, lowerUpcase },
}: any) => {
  return (
    <Ul>
      <PasswordStrengthItem
        isValid={minChar}
        text="Sua senha deve conter no mínimo 8 dígitos"
      />
      <PasswordStrengthItem
        isValid={lowerUpcase}
        text="1 caractere maiúsculo e 1 minúsculo"
      />
      <PasswordStrengthItem
        isValid={specialChar}
        text={'1 caractere especial (;:"\'?-_.!@#$%^&*(){}[]<>/|\\)'}
      />
      <PasswordStrengthItem isValid={number} text="1 número [0-9]" />
    </Ul>
  );
};

const PasswordStrengthItem = ({ isValid, text }: any) => {
  return (
    <li>
      <Span
        style={{ color: isValid ? 'green' : isValid !== null ? 'red' : '' }}
      >
        {isValid ? (
          <CheckIcon />
        ) : isValid !== null ? (
          <InvalidIcon />
        ) : (
          <CircleIcon />
        )}{' '}
        {text}
      </Span>
    </li>
  );
};
