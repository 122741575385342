import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import Router from './routers/Routers';

/* Styles */
import { AppContainer } from './Container/App';
import { GlobalStyle } from '../styles/global-styles';

export function App() {
  return (
    <BrowserRouter basename="/account-support">
      <Helmet titleTemplate="%s - Cognito Admin" defaultTitle="Cognito Admin">
        <meta name="description" content="Cognito Admin Application" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <AppContainer>
        <Router />
      </AppContainer>
      <GlobalStyle />
    </BrowserRouter>
  );
}
