import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;

  h1,
  h2 {
    font-size: 25px;
    margin: 0 0 5px;
  }

  p {
    font-size: 20px;
    margin: 0 0 30px;
  }

  svg {
    scale: 2;
    margin-bottom: 20px;
  }

  div.MobilePhones a {
    padding: 10px;
  }
`;

export const IconDone = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title />
    <path d="M64,0a64,64,0,1,0,64,64A64.07,64.07,0,0,0,64,0Zm0,122a58,58,0,1,1,58-58A58.07,58.07,0,0,1,64,122Z" />
    <path d="M87.9,42.36,50.42,79.22,40.17,68.43a3,3,0,0,0-4.35,4.13l12.35,13a3,3,0,0,0,2.12.93h.05a3,3,0,0,0,2.1-.86l39.65-39a3,3,0,1,0-4.21-4.28Z" />
  </svg>
);
