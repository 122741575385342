import React from 'react';
export const LogoEinstein = () => {
  return (
    <svg
      id="logo"
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="47.999"
      viewBox="0 0 48 47.999"
    >
      <g
        id="Grupo_10849"
        data-name="Grupo 10849"
        transform="translate(-46.254 -10.253)"
      >
        <g
          id="Grupo_10083"
          data-name="Grupo 10083"
          transform="translate(15311.254 3099.253)"
        >
          <path
            id="Caminho_636"
            data-name="Caminho 636"
            d="M147.908,64.748c-.905-1.53-2.966-1.967-4.464-2.185L143.1,62.5a57.567,57.567,0,0,1,4.4,7.461c.125-.312.25-.562.312-.749.718-1.873.749-3.372.094-4.464"
            transform="translate(-15372.243 -3138.039)"
            fill="#004f92"
          />
          <path
            id="Caminho_637"
            data-name="Caminho 637"
            d="M99.837,128.8c.812,2.123,1.655,3.184,2.685,3.4,1.28.25,2.966-.78,5.463-3.4a22.078,22.078,0,0,0,1.623-1.9,100.552,100.552,0,0,0-10.208.843c.094.187.437,1.061.437,1.061"
            transform="translate(-15342.094 -3182.454)"
            fill="#004f92"
          />
          <path
            id="Caminho_638"
            data-name="Caminho 638"
            d="M82.5,72.123a51.746,51.746,0,0,1,4.214,8.023c.375-.062.749-.094,1.124-.156A69.508,69.508,0,0,1,99.951,79.3a82.743,82.743,0,0,0,5.338-8.835,52.689,52.689,0,0,0-5.807-8.8,62.273,62.273,0,0,0-11.519.156,46.659,46.659,0,0,1-2.747,5.526q-1.545,2.669-2.716,4.776"
            transform="translate(-15330.487 -3137.359)"
            fill="#004f92"
          />
          <path
            id="Caminho_639"
            data-name="Caminho 639"
            d="M70.411,26.154a24.051,24.051,0,0,0-31.691-2.06A24.467,24.467,0,0,0,30.3,36.581a68.679,68.679,0,0,1,19.232-5.557c.5-1.592.87-3.091,1.181-4.4.652-2.747,1.119-4.714,2.392-5.089,1.367-.375,3.542,1.124,7.488,5.182h0l3.014,3.559a36.252,36.252,0,0,1,4.567.593l.155.031c1.243.25,3.884.78,4.971,2.716a4.954,4.954,0,0,1,0,4.214c-.684,1.936-1.429,3.653-2.206,5.338A46.137,46.137,0,0,1,72.8,48.226a1.618,1.618,0,0,0,.062.343,1.058,1.058,0,0,1-.28.812l-.124.156-.062-.187a43.516,43.516,0,0,0-1.8-5.12,43.443,43.443,0,0,1-3.6,6.025,42.581,42.581,0,0,1,8.855,1.5,24.569,24.569,0,0,0,1.585-8.585,24.114,24.114,0,0,0-7.022-17.014"
            transform="translate(-15294.433 -3108.099)"
            fill="#004f92"
          />
          <path
            id="Caminho_640"
            data-name="Caminho 640"
            d="M74.45,105.268c.562.843,2.154,1.093,5.151.78A56.423,56.423,0,0,0,76.23,98.9c-1.592,3.028-2.5,5.276-1.779,6.368"
            transform="translate(-15324.704 -3163.148)"
            fill="#004f92"
          />
          <path
            id="Caminho_641"
            data-name="Caminho 641"
            d="M111.535,44.688c-2.872-3.4-4.527-4.714-5.682-4.527-1.186.187-1.967,2.029-3.122,4.839l-.031.094a67.158,67.158,0,0,1,8.835-.406"
            transform="translate(-15344.363 -3122.574)"
            fill="#004f92"
          />
          <path
            id="Caminho_642"
            data-name="Caminho 642"
            d="M63.231,83.43c-1.4,1.748-3.013,3.559-5.062,5.682-2.981,3.091-4.627,4.5-5.963,4.152-.932-.25-1.615-1.311-2.205-3.528-.528-1.842-1.056-3.621-1.646-5.276l-2.174.312c-4.907.718-7.64.312-8.6-1.186-1.367-2.185,1.18-6.431,4.69-11.769-.776-1.155-1.615-2.248-2.485-3.34a1.715,1.715,0,0,1-.217-.281l-.124-.156.186-.031a1.145,1.145,0,0,1,.839.281c.776.843,1.553,1.748,2.3,2.716l.342-.531,1.584-2.4A26.193,26.193,0,0,0,46.926,63.7c-8.975,1.374-15.4,4.152-18.137,7.711-1.056,1.28-1.46,2.56-1.18,4.964a24.591,24.591,0,0,0,6.957,14.673A23.575,23.575,0,0,0,51.492,98.04a23.989,23.989,0,0,0,18.23-8.429c1.087-1.467,1.429-2.716.963-3.684-.683-1.467-3.2-2.31-7.454-2.5"
            transform="translate(-15292.522 -3139.041)"
            fill="#004f92"
          />
        </g>
      </g>
    </svg>
  );
};
