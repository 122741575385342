import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { HomePage } from '../pages/HomePage/Loadable';
import { NotFoundPage } from '../components/NotFoundPage/Loadable';
import { ChangePassword } from '../pages/ChangePassword';
import { ExpiredToken } from '../pages/Failed/ExpiredToken';
import { FailedActivated } from '../pages/Failed/Activated';
import { SuccessfullyActivated } from '../pages/Successfully/Activated';
import { ListEmails } from '../pages/ListEmails/Loadable';
import { ChangedPassword } from '../pages/Successfully/ChangedPassword/Loadable';
import { ConfirmAccount } from '../pages/ConfirmAccount';

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/account/confirm" element={<ConfirmAccount />} />
      <Route path="/account/change-password" element={<ChangePassword />} />
      <Route path="/failed/expired-token" element={<ExpiredToken />} />
      <Route path="/failed/activated" element={<FailedActivated />} />
      <Route
        path="/successfully/activated"
        element={<SuccessfullyActivated />}
      />
      <Route
        path="/successfully/changed-password"
        element={<ChangedPassword />}
      />
      <Route path="/requalification/list-emails" element={<ListEmails />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
