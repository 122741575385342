import styled from 'styled-components';

export const Wrapper = styled.div`
  * {
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
    font-family: Montserrat, Arial, Helvetica, sans-serif;
  }

  font-family: Montserrat, Arial, Helvetica, sans-serif;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  width: 100vw;
  height: 100%;
`;

export const GradientLine = styled.div`
  height: 8px;
  background-image: linear-gradient(
    to right,
    #ecb561 0%,
    #de656b 30%,
    #4595c4 60%,
    #3ad5ca 100%
  );
`;
