// sizes
// 320px — 480px: mobiles
// 481px — 768px: iPads, tablets
// 769px — 1024px: laptops
// 1025px — 1200px: desktops, screens
// 1201px e acima —  telas muito grandes, TVs

// sample (TODO: check order)
// @media screen and (min-width: 1025px) { html, body {background-color: gray} }
// @media screen and (min-width: 769px) { html, body {background-color: blue} }
// @media screen and (min-width: 481px) { html, body {background-color: green} }
// @media screen and (min-width: 320px) { html, body {background-color: red} }

// sample styled application
// const ProfilePage = styled.div`
// margin: auto;
// text-align: center;
// @media ${device.laptop} {
//     max-width: 800px;
// }
// @media ${device.desktop} {
//     max-width: 1400px;
// }
// `;

// Extra small  // <576px
// Small        // ≥576px
// Medium       // ≥768px
// Large        // ≥992px
// X-Large      // ≥1200px
// XX-Large     // ≥1400px
// .container	100%	540px	720px	960px	1140px	1320px
// .container-sm	100%	540px	720px	960px	1140px	1320px
// .container-md	100%	100%	720px	960px	1140px	1320px
// .container-lg	100%	100%	100%	960px	1140px	1320px
// .container-xl	100%	100%	100%	100%	1140px	1320px
// .container-xxl	100%	100%	100%	100%	100%	1320px
// .container-fluid	100%	100%	100%	100%	100%	100%

// <div class="container-sm">100% wide until small breakpoint</div>
// <div class="container-md">100% wide until medium breakpoint</div>
// <div class="container-lg">100% wide until large breakpoint</div>
// <div class="container-xl">100% wide until extra large breakpoint</div>
// <div class="container-xxl">100% wide until extra extra large breakpoint</div>

// pixel
const sizes = {
  mobile: 320, // xs: 1,
  tablet: 481, // sm: 768,
  laptop: 768, // md: 992,
  desktop: 1025, // lg: 1200,
};

export const devices = {
  mobile: `only screen and (min-width: ${
    sizes.mobile - 100
  }px) and (max-width: ${sizes.tablet - 1}px)`,
  tablet: `only screen and (min-width: ${sizes.tablet}px) and (max-width: ${
    sizes.laptop - 1
  }px)`,
  laptop: `only screen and (min-width: ${sizes.laptop}px) and (max-width: ${
    sizes.desktop - 1
  }px)`,
  desktop: `only screen and (min-width: ${sizes.desktop}px)`,
};
