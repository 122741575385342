import React from 'react';
import { Header } from '../../../core/Layout/Header';
import { Container, Wrapper } from './styled';

export const AppContainer = props => {
  const { children } = props;

  return (
    <Wrapper>
      <Header />
      <Container>{children}</Container>
    </Wrapper>
  );
};
