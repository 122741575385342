import React from 'react';
import { Container, IconDone } from './styles';

export function SuccessfullyActivated() {
  return (
    <Container>
      <IconDone />
      <h1>Conta ativada com sucesso!</h1>
      {/* <p>
        Baixe e conheça o app <b>Einstein Conecta</b> nas lojas:
      </p>
      <div className="MobilePhones">
        <a href="https://apps.apple.com/br/app/einstein-conecta/id1473323696">
          <img
            className="Icon"
            alt="icone apple store"
            width={150}
            height={45}
            src="https://icons-telemedicina.s3.amazonaws.com/appstore.png"
          />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.einstein.einsteinconecta&hl=en">
          <img
            className="Icon"
            alt="icone google play"
            width={150}
            height={45}
            src="https://icons-telemedicina.s3.amazonaws.com/playstore.png"
          />
        </a>
      </div> */}
    </Container>
  );
}
