import styled from 'styled-components';

export const Container = styled.div<{ color?: string }>`
  background-color: ${props => (props.color ? props.color : '#FFFFFF')};
  width: 100%;
  max-width: 311px;
  height: 106px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  button {
    border: none;
    background: transparent;
    color: #000;
    font-size: 16px;
  }
  .description {
    width: 100%;
    font-weight: 600;
    color: #707070;
    font-size: 13px;
    text-align: left;
    flex-grow: 1;
  }
  @media (min-width: 1100px) {
    max-width: 765px;
  }
`;
