import React, { useEffect } from 'react';
import { Container } from './style';
import { useNavigate } from 'react-router-dom';
import api from '../../../core/api';
import { Loader } from '../../components';
import { WithAutorization } from '../../Container/Authorization/withAutorization';

enum progress {
  'INITIAL' = 'INITIAL',
  'RUNNING' = 'RUNNING',
  'COMPLETED' = 'COMPLETED',
}

enum status {
  'INITIAL' = 'INITIAL',
  'SUCCESS' = 'SUCCESS',
  'FAILED' = 'FAILED',
}

type IActivedAccountProgress = 'INITIAL' | 'RUNNING' | 'COMPLETED';
type IActivedAccountStatus = 'INITIAL' | 'SUCCESS' | 'FAILED';

function ConfirmAccountPage() {
  const navigate = useNavigate();
  const [activeAccountProgress, setActiveAccountProgress] =
    React.useState<IActivedAccountProgress>(progress.INITIAL);
  const [activeAccountStatus, setActiveAccountStatus] =
    React.useState<IActivedAccountStatus>(status.INITIAL);

  async function initConfirmAccount() {
    try {
      setActiveAccountProgress(progress.RUNNING);
      let response = await api.init.post<any, any>('/patient/confirm-account');
      // console.log(response);
      const { _tag } = response.data;
      if (_tag === 'Right') {
        setActiveAccountStatus(status.SUCCESS);
      } else {
        setActiveAccountStatus(status.FAILED);
      }
    } catch (error) {
      setActiveAccountStatus(status.FAILED);
    } finally {
      setActiveAccountProgress(progress.COMPLETED);
    }
  }

  useEffect(() => {
    if (activeAccountProgress === progress.COMPLETED) {
      if (activeAccountStatus === status.SUCCESS) {
        navigate('/successfully/activated');
      }
      if (activeAccountStatus === status.FAILED) {
        navigate('/failed/activated');
      }
    }
  }, [activeAccountProgress, activeAccountStatus]);

  useEffect(() => {
    (async () => await initConfirmAccount())();
    console.log('useEffect::initConfirmAccount');
  }, []);

  return (
    <Container>
      {activeAccountProgress === progress.INITIAL ? (
        <>Verificando dados...</>
      ) : null}
      {activeAccountProgress === progress.RUNNING ? (
        <>Buscando informações...</>
      ) : null}
      {activeAccountProgress === progress.COMPLETED ? (
        <>
          {activeAccountStatus === status.INITIAL ? (
            <>
              <Loader />
              Aguarde...
            </>
          ) : null}
        </>
      ) : null}
    </Container>
  );
}

export const ConfirmAccount = () => WithAutorization(ConfirmAccountPage);
