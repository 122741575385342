import { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../../core/api';
import { Loader } from '../../components';

const URL_FAILED = '/failed/expired-token';

enum userContextState {
  'INITIAL' = 'INITIAL',
  'RUNNING' = 'RUNNING',
  'COMPLETED' = 'COMPLETED',
}
enum userAuthState {
  'INITIAL' = 'INITIAL',
  'SUCCESS' = 'SUCCESS',
  'FAILED' = 'FAILED',
}

type IUserContextState = 'INITIAL' | 'RUNNING' | 'COMPLETED';
type IUserAuthState = 'INITIAL' | 'SUCCESS' | 'FAILED';

export const WithAutorization = (Component: React.ComponentType) => {
  const navigate = useNavigate();
  const [contextState, setContextState] = useState<IUserContextState>(
    userContextState.INITIAL,
  );
  const [authState, setAuthState] = useState<IUserAuthState>(
    userAuthState.INITIAL,
  );

  // possivel hook
  function getDataFromUrl() {
    const dataParams = new URLSearchParams(window.location.search as string);
    const dataExtracted = {
      authToken: dataParams.get('authToken') || '',
      email: dataParams.get('email') || '',
      document: dataParams.get('document') || '',
    };
    return dataExtracted;
  }

  // possivel helper
  function parseTokenData(dataApiResponseToken) {
    const { document, email } = dataApiResponseToken.data || {};
    return {
      document,
      email,
    };
  }

  // handle auth -> pode separar
  async function authHandle(authToken: string) {
    try {
      setContextState(userContextState.RUNNING);

      api.authToken = authToken;
      const dataDecodeToken = await api.init.get('/patient/decode');
      const { document, email } = parseTokenData(dataDecodeToken);

      // TODO: VALIDAÇÃO
      // setUserContext({ document, email });
      // TODO: SUBSTITUIR useContext
      window.localStorage.setItem('authToken', authToken);
      window.localStorage.setItem('document', document);
      window.localStorage.setItem('email', email);

      setAuthState(userAuthState.SUCCESS);
    } catch (error: any) {
      setAuthState(userAuthState.FAILED);
    } finally {
      setContextState(userContextState.COMPLETED);
    }
  }

  useLayoutEffect(() => {
    if (authState === userAuthState.FAILED) {
      navigate(URL_FAILED);
    }
  }, [authState]);

  useEffect(() => {
    const { authToken } = getDataFromUrl();
    if (!authToken || authToken.length <= 1) {
      setAuthState(userAuthState.FAILED);
    }
    (async () => authHandle(authToken))();
  }, []);

  if (contextState !== userContextState.COMPLETED) {
    return <Loader />;
  } else {
    return <Component />;
  }
};
