import React from 'react';
import { ButtonFill, ButtonProps, ButtonOutline } from './styles';

type Props = ButtonProps & {
  children?: any;
  variation?: 'fill' | 'outline';
  disabled?: boolean;
  onClick?: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
  secondary?: boolean;
};

const Button: React.FC<Props> = props => {
  const {
    disabled,
    children,
    loading,
    onClick,
    variation = 'fill',
    ...rest
  } = props;

  const ButtonComponent = variation === 'fill' ? ButtonFill : ButtonOutline;

  return (
    <ButtonComponent
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      {...rest}
    >
      {children}
    </ButtonComponent>
  );
};

export { Button };
