import styled from 'styled-components';
import { devices } from '../devices';

const cardStyledDefault: React.CSSProperties = {
  backgroundColor: '#ffffff',
  // display: 'flex',
  // flexDirection: 'column',
  padding: '20px',
  borderRadius: '7px',
  // alignItems: 'center',
  // justifyContent: space-between,
  // overflow-y: auto,
};

export const CardStyled = styled.div<{
  xs?: React.CSSProperties;
  sm?: React.CSSProperties;
  md?: React.CSSProperties;
  lg?: React.CSSProperties;
  all?: React.CSSProperties;
}>`
  ${{ ...cardStyledDefault }}
  /* set only above */
	@media ${devices.mobile} {
    ${props => ({
      borderRadius: 0,
      // set up
      ...(props.all || null),
      ...(props.xs || null),
    })}
  }
  @media ${devices.tablet} {
    ${props => ({
      borderRadius: 0,
      // set up
      ...(props.all || null),
      ...(props.sm || null),
    })}
  }
  @media ${devices.laptop} {
    ${props => ({
      // set up
      ...(props.all || null),
      ...(props.md || null),
    })}
  }
  @media ${devices.desktop} {
    ${props => ({
      // set up
      ...(props.all || null),
      ...(props.lg || null),
    })}
  }
  .customClassName {
  }
`;
