import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;

  a {
    color: #0066ff;
  }

  h1,
  h2 {
    font-size: 25px;
    margin: 0 0 5px;
  }

  p {
    font-size: 20px;
    margin: 0 0 30px;
  }

  svg {
    scale: 2;
    margin-bottom: 20px;
  }

  ul {
    padding: 0;
    margin: 0 20px;
  }

  ul > li {
    font-size: 15px;
    margin-bottom: 10px;
    max-width: 400px;
  }
`;

export const IconDenied = () => (
  <svg
    viewBox="0 0 128 128"
    width="32"
    height="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title />
    <path d="M64,0a64,64,0,1,0,64,64A64.07,64.07,0,0,0,64,0Zm0,122a58,58,0,1,1,58-58A58.07,58.07,0,0,1,64,122Z" />
    <path d="M92.12,35.79a3,3,0,0,0-4.24,0L64,59.75l-23.87-24A3,3,0,0,0,35.88,40L59.76,64,35.88,88a3,3,0,0,0,4.25,4.24L64,68.25l23.88,24A3,3,0,0,0,92.13,88L68.24,64,92.13,40A3,3,0,0,0,92.12,35.79Z" />
  </svg>
);
